<template>
  <html-detail :newsdeta = "patent">
     <template v-slot:default="prop">
      <div class="flexStretch header">
        <div class="titlepanel">
          <div class="flexcent title"> {{ prop.header.title  }}  </div>
          <div class="flexcent time"  v-if="patent.createdTime">
            发布时间：{{ prop.header.createdTime | FormatDate('yyyy-MM-dd')}}
          </div>
        </div>
        <div class="btnpanel">
         <el-button @click="apply"  type="primary" plain class="btn"> 我要测评</el-button>
        </div>
      </div>
      <div>

      </div>
     </template>
  </html-detail>
<!-- 
    <div id="homebox" class="crumb">
      <div id="pagebox">
        <crumbs :list="crumblist" />
        
      </div>
    </div> -->
    
</template>

<script>
// import crumbs from "@/components/all/crumbs"; //引入导航栏组件
import htmlDetail from "@/components/all/htmlDetail"; //引入尾部组件

export default {
  name: 'AnalyseDetail',
  components: {
    htmlDetail,
    // crumbs, //引入导航栏组件
  },

  data() {
    return {   
      uid:'',
      patent: {},
      id: '',
      title: '',
      primary: 1, //一级菜单的选中样式
      istrue: true, //刷新页面
      crumblist: [
        {
          name: "首页",
          path: "/home",
          query: {},
        },
        {
          name: "行业应用检验检测",
          path: "/inspect",
          query: {},
        },
        {
          name: "详细信息",
          path: "",
          query: {},
        },
      ],
    }
  },
  
  created() {
    let checkLogin = this.until.checkLogin();
    // console.log(" check login ", checkLogin)
    if(!checkLogin) {
      // console.log(" !checkLogin  , jumpt /tologin ", !checkLogin)
      let path = this.$route.path
      this.until.jump.call(this,'/tologin', "push", {title:"行业应用检验检测", redirect:path});
    } else {
      console.log(" userinfo", this.until.getUserInfo())
      this.uid = this.until.getUserInfo().id
      this.init()
      this.getdata()
    }
  },
  methods: {
    init(){
      this.id = this.$route.params.id
      console.log("   patent id " ,   this.id)
    },
    getdata() {
      this.$api.getInspectDetail(this.id).then((res) => {
        this.patent = res.data;
        this.title = this.patent.title
        console.log("  get patent " , this.patent)
      });
    },
    apply(){
      console.log(" apply  ")
      this.$router.push("../apply/"+this.id)
    },
  },
};
</script>

<style scoped lang="less">
  .header{
    // border: 1px solid #f00;
    border-bottom: 1px solid #ececec;
    width: 100%;
    .titlepanel {
      width: 100%;
      margin-left: 150px;
     
    }
    .title {
      //  border: 1px solid #00f;
      margin-top: 20px;
      // width: 100%;
  
    }
    .time {
      height: 2rem;
      font-size: 0.875rem;
      padding-bottom: 0.5rem;
      border-bottom: 0 solid #ececec;
    }
    .btnpanel {
      display: flex;
      align-items: flex-end;
      padding-bottom: 10px;
      // border: 3px solid #00f;
    }
    .btn {
      width: 200px;
      // height: 40px;
      
    }
    // div{
    //    border: 1px solid #00f;
    // }
  }

</style>
