<template>
  <html-detail :newsdeta="newsdeta" />
</template>

<script>
// import detail from "@/components/all/detail";
import htmlDetail from "@/components/all/htmlDetail";

export default {
  
  components: {
    // detail,
    htmlDetail,
  },

  data() {
    return {
      id: '',
      title: '',
      newsdeta: {},
      primary: 1, //一级菜单的选中样式
      istrue: true, //刷新页面
      crumblist: [
        {
          name: "首页",
          path: "/home",
          query: {},
        },
        {
          name: "信息公告",
          path: "/news",
          query: {},
        },
        {
          name: "详情",
          path: "",
          query: {},
        },
      ], //面包屑数组
    };
  },
  // watch: {
  //   $route () {
  //     this.init()
  //   }
  // },
  created() {
    this.init()
  },
  mounted() {
      this.getdata();
  },
  methods: {
    init(){
      this.id = this.$route.params.id
      this.title = this.$route.query.title
    },
    getdata() {
      this.$api.getNewsDetail(this.id).then((res) => {
        this.newsdeta = res.data;
      });
    },
  },
};
</script>

<style scoped lang="less">

</style>
